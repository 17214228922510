<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="indigo lighten-5" flat>
        <BackButton />
        <v-toolbar-title>Налаштування системи</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-btn
          @click="restartService"
          :loading="isProcessingServiceRestarting"
          :disabled="isProcessingServiceRestarting"
          color="primary"
          small
        >
          <v-icon left> mdi-reload-alert </v-icon>
          Перезавантаження сервісу
        </v-btn>
      </v-toolbar>
      <v-list-item>
        <v-subheader class="orange--text text--lighten-3">
          Увага! Це налаштування змінних параметрів системи, які впливають на весь проект.
        </v-subheader>
      </v-list-item>
      <v-divider class="mb-5"></v-divider>
      <v-list>
        <div v-for="setting of settings" :key="setting.id" class="setting-row">
          <SettingsControl :data="setting" :key="key" @editSettings="editSettings" />
          <div class="ml-6">
            <code>{{ setting.key }}</code>
            <p class="caption text--secondary font-italic">
              {{ setting.description }}
            </p>
          </div>
        </div>
      </v-list>
      <div class="d-flex align-center px-4 my-4">
        <v-spacer></v-spacer>
        <div class="d-flex align-center">
          <span class="text--secondary caption mr-2">Рядків на сторінці:</span>
          <v-select
            v-model="paginationOptions.itemsPerPage"
            :items="itemsPerPageOptions"
            @change="paginateTo"
            style="width: 80px !important"
            solo
            dense
            hide-details
          ></v-select>
        </div>
        <v-pagination v-model="paginationOptions.page" :length="settingsMeta.total_pages" class="ml-4"></v-pagination>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="showUpdateSettingsModal = true" :disabled="isButtonDisabled" color="primary" small>Зберегти</v-btn>
        <v-btn @click="resetToDefault" :disabled="!savedSettingsModel" color="warning" small>
          Скинути до початкових налаштувань
        </v-btn>
      </v-card-actions>
    </v-card>

    <ConfirmActionModal @closeModal="showUpdateSettingsModal = false" :showModal="showUpdateSettingsModal">
      <template v-slot:title>Зберегти налаштування?</template>
      <template v-slot:text>
        <span class="red--text text--lighten-1">
          Ви вносите зміни у налаштування системи! Якщо ці зміни не коректні - це призведе до зупинки процесів сервера!
        </span>
      </template>
      <template v-slot:buttons>
        <v-btn @click="save" color="primary" text>Так</v-btn>
        <v-btn @click="showUpdateSettingsModal = false" text>Нi</v-btn>
      </template>
    </ConfirmActionModal>
  </v-container>
</template>

<script>
import CentrifugeApi from '@/services/CentrifugeApi'
import { mapActions, mapState } from 'vuex'
import { isObjectEmpty } from '@/helpers/isObjectEmpty'
import BackButton from '@/components/common/BackButton.vue'

export default {
  name: 'Settings',

  components: {
    BackButton,
    SettingsControl: () => import('@/components/admin/settings/SettingsControl.vue'),
    ConfirmActionModal: () => import('@/components/dialogs/ConfirmActionModal'),
  },

  data: () => ({
    settingsModel: {},
    ws: null,
    wsSubscription: null,
    wsChannel: 'admin:services',
    isProcessingServiceRestarting: false,
    key: 0,
    showUpdateSettingsModal: false,
    paginationOptions: {
      page: 1,
      itemsPerPage: 5,
    },
    itemsPerPageOptions: [5, 10, 15, 20],
  }),

  computed: {
    ...mapState('settings', ['settings', 'defaultSettings', 'savedSettingsModel', 'settingsMeta']),

    isButtonDisabled() {
      return isObjectEmpty(this.settingsModel)
    },
  },

  watch: {
    settingsModel: {
      handler(val) {
        this.updateSettingsModel(val)
      },
      deep: true,
    },
    paginationOptions: {
      async handler(val) {
        await this.loadSettings({
          page: val.page,
          limit: val.itemsPerPage,
        })
      },
      deep: true,
    },
  },

  created() {
    this.initialize()
  },

  beforeDestroy() {
    this.disconnectFromWs()
  },

  methods: {
    isObjectEmpty,
    ...mapActions('settings', ['loadSettings', 'updateSettings', 'updateSettingsModel']),
    ...mapActions('logs', ['displayInfoAlert']),
    ...mapActions('admin', ['restartProcessingService']),

    async initialize() {
      await this.loadSettings()
      if (this.savedSettingsModel) this.settingsModel = { ...this.savedSettingsModel }
      if (!this.ws) await this.connectToWs()
    },
    async connectToWs() {
      this.ws = new CentrifugeApi()
      await this.ws.connectToCentrifuge()
      this.wsSubscription = this.ws.centrifuge.newSubscription(this.wsChannel)
      this.wsSubscription.on('publication', async (ctx) => {
        await this.displayInfoAlert(ctx.data)
        this.isProcessingServiceRestarting = false
      })
      this.wsSubscription.subscribe()
    },
    disconnectFromWs() {
      if (this.wsSubscription) this.wsSubscription.unsubscribe()
      this.ws.disconnectFromCentrifuge()
    },
    async restartService() {
      this.isProcessingServiceRestarting = true
      await this.restartProcessingService()
    },
    editSettings(key, value) {
      this.$set(this.settingsModel, key, value)
    },
    async save() {
      this.showUpdateSettingsModal = false
      const payload = {
        page: this.paginationOptions.page,
        limit: this.paginationOptions.itemsPerPage,
      }
      await this.updateSettings(this.settingsModel)
      await this.loadSettings(payload)
      this.key++
    },
    async resetToDefault() {
      const modifiedKeys = Object.keys(this.settingsModel).filter((settingsModelKey) => {
        return this.defaultSettings.some((setting) => {
          return setting.key === settingsModelKey && this.settingsModel[settingsModelKey] !== setting.value
        })
      })
      modifiedKeys.forEach((modifiedKey) => {
        const matchingSetting = this.defaultSettings.find((setting) => setting.key === modifiedKey)
        if (matchingSetting) {
          this.$set(this.settingsModel, modifiedKey, matchingSetting.actual_value)
        }
      })
      await this.save()
    },
    paginateTo() {
      this.paginationOptions.page = 1
    },
  },
}
</script>

<style scoped lang="scss">
/* displayed error-message if <v-list-item-action> used */
/*::v-deep .v-list-item__action .v-input .v-messages {
  display: block;
  margin-top: 3px;
}*/
.setting-row {
  display: flex;
  padding: 0 16px;
  min-height: 80px;
  & > div:first-child {
    flex-basis: 150px;
  }
  & > div:last-child {
    flex-basis: 100%;
  }
}
</style>
